/** @jsx jsx */
import React from "react"
import { jsx, Select } from "theme-ui"
import { formatReg, validate as regValidate } from "uk-vehicle-number-format"
import { getLocalStorageItem } from "../util/local-storage-wrapper"
import { AddressFinder, formatDisplayAddress } from "./address-finder"

const phoneValidate = (v) => {
  if (v.startsWith('+') && v[1] !== '0' && v.length >= 13) {
    return true
  }
  if (v.startsWith('0') && v.length >= 11) {
    return true
  }
  return false
}

export const validator = {
  regNo: v => !v || regValidate(v),
  email: v => /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(v),
  phone: v => phoneValidate(v)
}

const phoneTransform = (v) => {
  return v.replace(/[^0-9|+]/gi, '')
}

export const transformer = {
  regNo: v => v.replace(/[^0-9a-z]/gi, '').toUpperCase(),
  email: v => v.toLowerCase(),
  phone: phoneTransform,
  integer: v => v.replace(/[^0-9]/gi, '')
}

export const findDefaultValue = storageKey => {
  const keys = storageKey.split('.')
  if (keys.length === 2) {
    const storageItem = getLocalStorageItem(keys[0])
    return (storageItem && storageItem[keys[1]]) || ''
  }
  return ''
}

const StaticField = ({ localStorageValue, label, name }) => {
  const [value, setValue] = React.useState(null)

  React.useEffect(() => {
    if (!value) {
      setValue(findDefaultValue(localStorageValue))
    }
  }, [value, setValue, localStorageValue])

  let formattedValue = value

  if (value) {
    if (name === 'reg') {
      formattedValue = formatReg(value)
    }
    if (name === 'address') {
      formattedValue = formatDisplayAddress(value)
    }
  }

  return <div className="field-static">
    <span className="static-label">{label}:</span> {formattedValue}
  </div>
}

const FormField = ({
  name,
  validate = () => true,
  required = false,
  setFormField,
  formSubmit = false,
  label,
  type = 'text',
  placeholder = 'Type something',
  transform = (v) => v,
  options = [],
  hiddenValue = '',
  localStorageValue = '',
  disabled = false,
  inputMode = ''
}) => {
  const [hasError, setHasError] = React.useState('')

  const [value, setValue] = React
    .useState(findDefaultValue(localStorageValue) || hiddenValue)

  const validateMe = (val) => {
    if (type === 'address') console.log(hiddenValue)
    const valid = validate(val)
    if (required && !val) return 'required'
    if (!valid) return 'invalid'
    return ''
  }

  const selectOptions = [
    <option
      key={`opt-${name}-first`}
      className="placeholder" value=""
    >
      Select something
    </option>,
    ...options.map((o, i) => <option
      key={`opt-${name}-${i}`}
      value={o}
    >
      {o}
    </option>)
  ]

  const submitError = formSubmit && validateMe(value)
  const error = submitError || hasError

  const handleChange = e => {
    e.preventDefault()
    const val = transform(e.target.value)
    const validation = validateMe(val)
    setHasError(validation)
    setFormField(name, validation === '' ? val : false)
    setValue(val)
  }

  const modeProp = inputMode ? { inputMode } : {}

  const fieldProps = {
    ...{
      className: error ? 'field-error' : '',
      onChange: handleChange,
      id: name,
      name,
      type,
      placeholder,
      value,
      // setValue,
      disabled
    }, ...modeProp
  }

  return type === 'hidden'
    ? <input {...fieldProps} />
    : <div>
        <label>
          {label} {required ? '' : '(optional)'}<span className='label-error'>{error ? ` - ${error}` : ''}</span>
          {type === 'textarea' ? <textarea {...fieldProps}/> : null}
          {type === 'select' ? <div className="cpd-select"><Select{...fieldProps}>{selectOptions}</Select></div> : null}
          {type === 'address' ? <AddressFinder {...fieldProps} setValue={setValue} localStorageValue={localStorageValue} setFormField={setFormField}/> : null}
          {['text', 'email', 'number', 'date'].includes(type) ? <input {...fieldProps}/> : null}
        </label>
      </div>
}

export const CpdForm = ({
  title,
  disclaimer = '',
  expectedDirectDebitDate = '',
  name,
  action,
  fields,
  showPreviousButton = false,
  previousAction = () => {},
  method = 'POST',
  submitLabel = 'Submit',
  submitAction = (e) => { e.target.submit() },
  cancelLabel = '',
  cancelAction = () => {},
  netlify = false,
  playback = false,
  isH1 = false
}) => {
  const defaultRequiredValues = fields.reduce((prev, f) => {
    const currentVal = findDefaultValue(f.localStorageValue || '')
    const returnVal = !f.required && !currentVal ? '' : currentVal || false

    return { ...prev, [f.name]: returnVal }
  }, {})

  const [requiredValues, setRequiredValues] = React.useState(defaultRequiredValues)
  const [formSubmit, setFormSubmit] = React.useState(false)

  const setFormField = (name, value) => {
    setRequiredValues({ ...requiredValues, [name]: value })
  }

  const preparedFields = fields.map((f, i) => {
    const key = `${name}-${i}`
    return { ...f, key, setFormField, formSubmit }
  })

  const requiredFieldValues = fields.map(f => {
    const currentVal = requiredValues[f.name]
    return !f.required && typeof currentVal === 'undefined' ? true : currentVal
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!requiredFieldValues.some(f => f === false)) {
      submitAction(e, requiredValues)
    }
    else {
      setFormSubmit(true)
    }
  }
  
  const buttonClassLabel = "button button-electric-blue " + title.replace(/ +/g, '-').toLowerCase();
  const buttons = <div className="form-buttons">
    {
      cancelLabel && cancelAction
        ? <button onClick={cancelAction} className="button button-dark cancel-signup-button" >{cancelLabel}</button>
        : null
    }
    
    <button className={buttonClassLabel} type="submit">
      {playback ? 'Change' : submitLabel}
    </button>
  </div>

  const displayElements = preparedFields
    .map(f => playback
      ? <StaticField key={f.key} name={f.name} label={f.label} localStorageValue={f.localStorageValue || ''}/>
      : <FormField {...f}/>)

  const displayFields = playback
    ? <div className='static-fields'>
      {displayElements}
    </div>
    : <>
      {displayElements}
    </>

  const formTitle = isH1
    ? <h1>{title}</h1>
    : <h3>{title}</h3>

  const form = <div className={`cpd-form${playback ? ' static' : ''}`}>
    {formTitle}
    {!playback ? <div className="enter-details-corrrectly" dangerouslySetInnerHTML={{ __html: disclaimer }}/> : null }
    {expectedDirectDebitDate && !playback ? <p className="first-direct-debit-date" >Your first direct debit won’t be collected until {expectedDirectDebitDate}</p> : null }
    <form
      onSubmit={handleSubmit}
      action={action}
      name={name}
      method={method}
      data-netlify={netlify ? 'true' : 'false'}
      data-netlify-honeypot="bot-field"
    >
      {
        netlify
          ? <FormField type="hidden" name="form-name" hiddenValue={name}/>
          : null
      }
      {displayFields}
      {buttons}
    </form>
    {
      showPreviousButton && !playback
        ? <button className="button button-dark" onClick={previousAction}>Previous step</button>
        : null
    }
  </div>

  return form
}
