/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { getAddress, getSuggestions } from "../api/get-address"
import { findDefaultValue } from "./cpd-form"

const addressJoiner = ', '

export const formatDisplayAddress = (address) =>
  (address && address.length && address
    .split(addressJoiner)
    .filter(line => !!line)
    .join(addressJoiner)) || ''

export const AddressFinder = ({
  className,
  id,
  name,
  placeholder,
  type, disabled,
  setFormField,
  localStorageValue,
  value,
  setValue
}) => {
  const defaultAddress = findDefaultValue(localStorageValue)

  const [suggestions, setSuggestions] = React.useState([])
  const [address, setAddress] = React.useState(defaultAddress)
  const [showInput, setShowInput] = React.useState(!address)
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    if (!!address && showInput) {
      inputRef.current.focus()
    }
  }, [showInput, address])

  const handleChange = (e) => {
    const fragment = e.target.value
    setValue(fragment)

    if (fragment.length > 3) {
      getSuggestions(fragment).then((x) => setSuggestions(x))
    }
    else {
      setSuggestions([])
    }
  }

  const handleSelect = (e, val) => {
    e.preventDefault()
    getAddress(val).then(address => {
      const addressString = [
        address.line_1,
        address.line_2 || address.locality || address.line_3 || address.line_4,
        address.town_or_city,
        address.postcode
      ].join(addressJoiner)
      setAddress(addressString)
      setSuggestions(null)
      setValue(addressString)
      setFormField(name, addressString)
      setShowInput(false)
    })
  }

  const handleChangeAddress = (e) => {
    e.preventDefault()
    setSuggestions(null)
    setValue('')
    setShowInput(true)
  }

  const fieldProps = { className, name, id, placeholder, type, disabled, value }

  return <div className="address-field">
    <div
      style={{ display: showInput ? 'inline' : 'none' }}
      role="presentation"
      // onBlur={() => setTimeout(() => handleBlur(), 500)}
    >
      <input
        className={className}
        autoComplete="new-password"
        aria-autocomplete="none"
        ref={inputRef}
        placeholder={placeholder}
        type="search"
        name="search-term"
        onChange={handleChange}
        value={value}
      />
      <div className="address-suggestions">
        <div className="suggestion-buttons">
          {
            suggestions && suggestions
              .map(suggestion =>
                <button
                  id={suggestion.id}
                  key={suggestion.id}
                  onClick={e => { handleSelect(e, suggestion.id) }}
                >
                  {suggestion.address}
                </button>)
          }
        </div>
      </div>
    </div>
    <input {...fieldProps} type="hidden" disabled value={address}/>
    <div className="chosen-address" style={{ display: showInput ? 'none' : 'inline'}}>
      {formatDisplayAddress(address)}
      <button className="button button-green" onClick={handleChangeAddress}>
        Change
      </button>
    </div>
  </div>
}
