import Client from 'getaddress-api'

// const dummyData = {
//   'Y2IwNGUzYTA4MmM4NDc1IDIzNDMxNDExMDEgYTVkY2I3M2QzNTM3OGIw': {
//     "postcode": "BS16 3UH",
//     "formatted_address": [
//       "Polski Smak",
//       "711 Fishponds Road",
//       "",
//       "Fishponds, Bristol",
//       ""
//     ]
//   },
//   'ZTEzOTZmYjQ4MGNiZTE0IDQwNjAyOTM0MSBhNWRjYjczZDM1Mzc4YjA=': {
//     "postcode": "BS16 3UH",
//     "formatted_address": [
//       "Polski Smak not",
//       "711 Fishponds Road mind",
//       "",
//       "Fishponds, Bristol",
//       ""
//     ]
//   }
// }


const api = typeof window !== 'undefined'
  ? new Client("Iwbdm_KFLkyrzqaxTquMOA34799")
  : null

export const getSuggestions = async (fragment) => {
  if (api) {
    const autocompleteResult = await api.autocomplete(fragment)

    if (autocompleteResult.isSuccess) {
      const { suggestions } = autocompleteResult.toSuccess()

      return suggestions
    }
  }

  return []
}

export const getAddress = async (id) => {
  if (api) {
    const getResult = await api.get(id)
    if (getResult.isSuccess) {
      const { address } = getResult.toSuccess()

      return address
    }
  }
  return ''
}

// export const getAddressStub = async (id) => dummyData[id]
//
// export const getSuggestionStub = async () => ([
//   {
//     "address": "Polski Smak, 711 Fishponds Road, Fishponds, Bristol",
//     "url": "/get/Y2IwNGUzYTA4MmM4NDc1IDIzNDMxNDExMDEgYTVkY2I3M2QzNTM3OGIw",
//     "id": "Y2IwNGUzYTA4MmM4NDc1IDIzNDMxNDExMDEgYTVkY2I3M2QzNTM3OGIw"
//   },
//   {
//     "address": "Polski Smak Bristol Ltd, 711 Fishponds Road, Fishponds, Bristol",
//     "url": "/get/ZTEzOTZmYjQ4MGNiZTE0IDQwNjAyOTM0MSBhNWRjYjczZDM1Mzc4YjA=",
//     "id": "ZTEzOTZmYjQ4MGNiZTE0IDQwNjAyOTM0MSBhNWRjYjczZDM1Mzc4YjA="
//   }
//   ]
// )
