export const setLocalStorageItem = (key, value) => {
  if (typeof window !== 'undefined' && localStorage && localStorage.setItem) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const getLocalStorageItem = (key) => {
  if (typeof window !== 'undefined' && localStorage && localStorage.getItem) {
    const stringValue = localStorage.getItem(key)

    return JSON.parse(stringValue)
  }
  return null
}

export const updateStorageItem = (key, newValues) => {
  const existingValue = getLocalStorageItem(key)
  console.log(key, existingValue, newValues)
  if (existingValue) {
    setLocalStorageItem(key, { ...existingValue, ...newValues })
  }
}

export const dataStorageKeys = {
  vehicleData: 'vehicle-data',
  quoteData: 'cpd-quote',
  selectedProduct: 'selected-product',
  userData: 'user-data',
  userBankData: 'user-bank-data'
}
